export enum TagType {
  REACT = 'React',
  REACT_NATIVE = 'React Native',
  SPRING_BOOT = 'Spring Boot',
  DOCKER = 'Docker',
  HIGHCHARTS = 'Highcharts',
  CMS = 'Cms',
  TS = 'TS',
  JAVA = "Java",
  JAVA_FX = 'JavaFX',
  JUNIT = 'Junit 5',
  MAPBOX = 'Mapbox',
  MOBX = 'MobX',
  MUI = 'MUI',
  GOOGLE_MAPS = 'Google Maps',
  NEXTJS = 'NextJS'
}
